import React from 'react';

// Style import
import '../styles/KoralHeader.css';

// Image import
import Reef from '../assets/reef.mp4';

const KoralHeader = () => {
  return (
    <header className="koral-header">
      <video className="video-background" autoPlay loop muted>
        <source src={Reef} type="video/mp4" />
      </video>
      <div className="koral-overlay"></div>
      <div className="koral-text">
        <h4>Empowering Change, Restoring Reefs</h4>
        <h1>The Koral Initiative</h1>
        <p>
          At Koral Digital, we believe in more than just digital transformation. Through the Koral Initiative, we commit to supporting coral reef restoration by contributing a percentage of our projects to reef preservation. We aim to reduce our carbon footprint as a tech company while making a positive environmental impact. Join us in building a sustainable future.
        </p>
      </div>
    </header>
  );
};

export default KoralHeader;
