import React from 'react';

// Style Import
import '../styles/Services.css';

// Component Imports
import Card from '../components/serviceCard';
import Footer from '../components/Footer';

// Image Imports
import Campaigns from '../assets/campaigns.jpg';
import Analytics from '../assets/analytics.jpg';
import LeadGeneration from '../assets/lead-generation.jpg';
import MarketResearch from '../assets/market-research.jpg';
import Branding from '../assets/branding.jpg';
import SalesSupport from '../assets/sales-support.jpg';
import ContentCreation from '../assets/content-creation.jpg';
import Optimization from '../assets/optimization.jpg';

const services = [
  {
    image: Campaigns,
    title: 'Marketing Campaign Development',
    description: 'We specialize in building highly effective marketing campaigns to help you connect with your ideal customers. Our team uses data-driven strategies to design targeted campaigns that generate high-quality leads and maximize your ROI.',
  },
  {
    image: LeadGeneration,
    title: 'Lead Generation & Sales Support',
    description: 'Our lead generation services are designed to bring you consistent, qualified leads for your business. From initial prospecting to sales support, we help your sales team close more deals with less effort, maximizing revenue opportunities.',
  },
  {
    image: Analytics,
    title: 'Marketing Analytics & Reporting',
    description: 'Gain deep insights into your marketing performance with our comprehensive analytics and reporting. We provide real-time data, key performance metrics, and campaign analysis to help you make informed business decisions and drive continuous improvement.',
  },
  {
    image: Optimization,
    title: 'Campaign Optimization & Targeting',
    description: 'Optimize your marketing efforts with precision targeting and ongoing campaign adjustments. We ensure that your campaigns are continuously fine-tuned to reach the right audience and deliver optimal results.',
  },
  {
    image: MarketResearch,
    title: 'Market & Competitive Research',
    description: 'Our market research services provide you with a clear understanding of your target market and competitors. We analyze trends, customer behavior, and competitor strategies to inform your marketing efforts and give you a competitive edge.',
  },
  {
    image: ContentCreation,
    title: 'Content Creation & Ad Design',
    description: 'Engage your audience with compelling content and ad design. Our team creates high-quality content that captures attention, builds brand awareness, and drives engagement, helping you stand out in a crowded market.',
  },
  {
    image: SalesSupport,
    title: 'Sales Strategy & Support',
    description: 'We work closely with your sales team to provide strategic support, helping you close more deals and grow your revenue. Our sales support services include lead nurturing, sales funnel optimization, and ongoing consultation.',
  },
  {
    image: Branding,
    title: 'Branding & Identity',
    description: 'Build a strong and memorable brand with our comprehensive branding services. From brand identity development to logo design and brand guidelines, we help you establish a brand that resonates with your audience and sets you apart from competitors.',
  },
];

const Services = () => {
  return (
    <div className="service__page">
      <div className="service__header">
        <h1>Our Services</h1>
        <p>We help roofing and solar companies grow through strategic marketing, targeted advertising, and sales support. Our goal is to deliver measurable results and long-term business growth.</p>
      </div>
      <div className="service__content">
        {services.map((service, index) => (
          <Card
            key={index}
            image={service.image}
            title={service.title}
            description={service.description}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Services;
