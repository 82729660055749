import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'; // Import Link for navigation

// Style Import
import '../styles/About.css';

// Image Imports
import Ad from '../assets/elevateAd.png';

// Icon Imports
import { RiNumber1, RiNumber2, RiNumber3, RiNumber4 } from 'react-icons/ri';
import { GiProcessor } from 'react-icons/gi';

// Component Imports
import AboutPageHeader from '../components/AboutHeader';
import ProcessCard from '../components/processCard';
import Footer from '../components/Footer';

const About = () => {
  return (
    <motion.div
      className="about"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="main__content">
        <AboutPageHeader />
        <section className="about_section">
          <div className="section__text">
            <h1 className="section__title">Targeted Ad Solutions</h1>
            <h3 className="section__subtitle">Our Mission</h3>
            <p>
              Koral Digital was founded with a single goal in mind: to provide roofing and solar companies with the most effective digital marketing strategies to grow their businesses. 
              Our journey began with a deep understanding of the unique challenges these industries face when it comes to lead generation and customer acquisition. We realized that many businesses in these sectors struggle to reach their target audience effectively, resulting in missed opportunities for growth.
            </p>
            <p>
              Over the years, we've refined our approach, developing a specialized framework for creating and managing highly targeted ad solutions that not only capture leads but convert them into loyal customers. By leveraging data-driven strategies, advanced ad technologies, and our extensive knowledge of both the roofing and solar markets, we are able to deliver tangible results that directly impact your bottom line.
            </p>
            <p>
              Our mission is simple: to help your business thrive by connecting you with the right customers at the right time. Through transparent communication and a results-oriented mindset, we aim to build long-lasting partnerships with our clients, aligning our success with yours. We measure our effectiveness not just by the number of leads we generate, but by the value and longevity those leads bring to your business.
            </p>
            <p>
              Koral Digital’s ultimate goal is to become the go-to digital marketing partner for roofing and solar companies across the country. By focusing on high-impact, cost-efficient ad strategies, we help companies stay competitive in a rapidly evolving digital landscape. Our solutions are tailored to your specific business goals, whether you want to increase installations, build brand awareness, or dominate your local market.
            </p>
            <p>
              With our extensive experience in both the roofing and solar industries, we understand the nuances that can make or break a campaign. Our team is committed to staying on the cutting edge of digital marketing, continuously refining our tactics to ensure the best return on investment for our clients. Whether it's pay-per-click advertising, social media marketing, or search engine optimization, we use the most effective methods to get your brand in front of your ideal customers.
            </p>
            <p>
              At Koral Digital, we believe that our success is tied directly to yours. That’s why we emphasize ongoing optimization and personalized service, offering you the flexibility to adjust strategies as your business grows. We don’t just deliver leads—we deliver long-term growth, helping you scale your operations, increase profitability, and build a reputation as a leader in your industry.
            </p>
          </div>
          <div className="section__image-container">
            <img src={Ad} alt="agency" className="section__image" />
          </div>
        </section>

        <section className="process_section">
          <h3><GiProcessor /> Our Process</h3>
          <div className="process__container">
            <ProcessCard
              number={<RiNumber1 />}
              title="Market Research"
              description="We conduct in-depth market research to identify target audiences and the most effective platforms to reach potential customers."
              deliverables={[
                'Market analysis report',
                'Competitor insights',
                'Audience targeting strategy',
                'Campaign planning',
                'Ad performance projections'
              ]}
            />
            <ProcessCard
              number={<RiNumber2 />}
              title="Ad Strategy & Design"
              description="We create tailored ad strategies and designs that capture attention and drive engagement, focusing on high-conversion elements."
              deliverables={[
                'Custom ad designs',
                'Platform-specific strategies',
                'Landing page optimization',
                'Creative content development',
                'A/B testing'
              ]}
            />
            <ProcessCard
              number={<RiNumber3 />}
              title="Campaign Management"
              description="Our team manages your ad campaigns, optimizing for performance while providing transparent reporting and insights."
              deliverables={[
                'Ongoing campaign optimization',
                'Real-time reporting',
                'Budget management',
                'Lead generation tracking',
                'Performance reviews'
              ]}
            />
            <ProcessCard
              number={<RiNumber4 />}
              title="Performance Monitoring"
              description="We continuously monitor and adjust campaigns to ensure you receive the highest return on investment, refining based on performance data."
              deliverables={[
                'Campaign analytics',
                'ROI tracking',
                'Continuous adjustments',
                'Conversion rate optimization',
                'Monthly performance reports'
              ]}
            />
          </div>
        </section>

        {/* Contact Button */}
        <div className="contact-section">
          <Link to="/contact">
            <button className="contact-btn">Get In Touch</button>
          </Link>
        </div>

        <Footer />
      </div>
    </motion.div>
  );
};

export default About;
