import React from 'react';
import { motion } from 'framer-motion';

// Component imports
import Footer from '../components/Footer';
import KoralHeader from '../components/KoralHeader';

// Style import
import '../styles/KoralInitiativePage.css';

// Image imports
// import Fish from '../assets/fishCoral.mp4';
import CoralReef from '../assets/coral-reef.jpg';
import HawaiiCoral from '../assets/hawaiiCoral.jpg';
import CoralLogo from '../assets/coral-restoration.png';

const KoralInitiativePage = () => {
  return (
    <motion.div
      className="koral-initiative"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <KoralHeader />

      {/* Coral Reefs Overview Section */}
      <section id="about-coral">
        <h2>The Importance of Coral Reefs</h2>
        <p>
          Coral reefs are more than just vibrant underwater ecosystems—they are the cornerstone of oceanic life. They provide food, shelter, and a breeding ground for countless species while playing a vital role in the health of our oceans and climate regulation. Sadly, coral reefs are disappearing due to rising sea temperatures, ocean acidification, and human activities, putting the health of our planet at risk.
        </p>
        <div className="coral-content">
          <ul>
            <li>Coral reefs support over 25% of all marine species, despite covering less than 1% of the ocean floor.</li>
            <li>They protect coastlines from erosion, storms, and flooding by acting as natural barriers.</li>
            <li>Coral reefs contribute to the global economy through tourism, fishing, and biotechnology.</li>
            <li>Coral reefs are essential for carbon sequestration, helping to absorb carbon dioxide and reduce the impacts of climate change.</li>
          </ul>
          <div className="coral-image-container">
            <img src={CoralReef} alt="Coral Reef" />
          </div>
        </div>
      </section>

      {/* The Connection to Hawaii Section */}
      <section id="hawaii-coral">
        <h2>Coral Reefs & Their Significance to Hawaii</h2>
        <p>
          As a company founded in Hawaii, we have a deep connection to the ocean and its coral reefs. Hawaii's reefs are not just beautiful—they are an integral part of the state's cultural and environmental heritage. Hawaiian communities have long relied on coral reefs for food, coastal protection, and spiritual significance. These reefs protect Hawaii’s shorelines from powerful ocean swells and are home to a diversity of marine life found nowhere else in the world.
        </p>
        <div className="hawaii-content">
          <img src={HawaiiCoral} alt="Hawaiian Coral Reef" />
          <p>
            Unfortunately, Hawaiian reefs are also under threat. Rising sea temperatures, invasive species, and coastal development are leading to coral bleaching and reef degradation. Our mission is to ensure that these reefs continue to thrive, preserving not only Hawaii’s natural beauty but also its way of life. The Koral Initiative is our commitment to helping conserve these vital ecosystems by contributing to restoration projects and promoting sustainable practices.
          </p>
        </div>
      </section>

      {/* Why We Chose Coral Reefs Section */}
      <section id="why-coral">
        <h2>Why We Chose to Support Coral Reefs</h2>
        <p>
          The Koral Initiative was born out of our passion for the ocean and a recognition of the urgent need to protect coral reefs. As a digital marketing agency, we understand that every business has a responsibility to contribute to the well-being of the planet. Coral reefs, as vital ecosystems that support marine biodiversity, climate regulation, and coastal protection, are at the forefront of our environmental efforts.
        </p>
        <p>
          We chose to focus on coral reef restoration because it’s a cause that aligns with both our values and our heritage. Coral reefs are not just local treasures for islands like Hawaii—they are crucial to the health of oceans worldwide. By donating a percentage of our project revenues to coral reef conservation, we hope to make a meaningful impact on both local and global scales.
        </p>
      </section>

      {/* Koral Digital’s Mission Section */}
      <section id="koral-digital">
        <h2>Koral Digital’s Environmental Mission</h2>
        <p>
          At Koral Digital, we strive to be more than just a digital marketing agency. Our mission is to use our platform and resources to make a difference. Through the Koral Initiative, we actively contribute to coral reef restoration by donating a portion of our revenues to the Coral Restoration Foundation. 
        </p>
        <p>
          In addition to our support for coral reefs, we are committed to reducing our carbon footprint as a tech company. We use energy-efficient technologies, minimize waste, and continuously seek ways to operate sustainably. We believe that every step we take—whether in the office or through our projects—can contribute to a healthier planet.
        </p>
        <a href="https://coralrestoration.org/" target="_blank" rel="noreferrer" className="button">
          <img src={CoralLogo} alt="Coral Restoration Foundation Logo" />
        </a>
      </section>

      <Footer />
    </motion.div>
  );
};

export default KoralInitiativePage;
