import React, { useState } from 'react';

// Style import
import '../styles/serviceCard.css';

const Card = ({ image, title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`card ${isOpen ? 'expanded' : ''}`}>
      <img src={image} alt={title} />
      <h2>{title}</h2>
      {isOpen && (
        <div className="service-description">
          <p>{description}</p>
        </div>
      )}
      <button onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? 'Close' : 'Learn More'}
      </button>
    </div>
  );
};

export default Card;
