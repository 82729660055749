import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'


//Icon Imports
import { AiOutlineInstagram, AiOutlineMail } from 'react-icons/ai'
import { FaYelp } from 'react-icons/fa'
import { SiGooglemybusiness } from 'react-icons/si'
import { BsArrowRight } from 'react-icons/bs'

//Style Import
import '../styles/Home.css'

//Video Import
import Coral from '../assets/coralHome.mp4'

const Home = () => {
  return (
    <motion.div
    className="home"
    initial={{opacity: 0}}
    animate={{opacity: 1}}
    exit={{opacity: 0}}
    >
    <div className='main'>
    <video className='bg-video' src={ Coral } autoPlay muted loop />
      <div className='content'>
          <h1>Targeted Marketing Solutions</h1>
          <p>
          Koral Digital is a results-driven digital marketing agency focused on lead generation and business growth for companies in the roofing and solar industries. 
          We offer tailored marketing solutions designed to deliver high-quality leads that fuel your business's success. With an emphasis on transparency, measurable outcomes, and a collaborative 
          partnership, Koral Digital ensures that your success is our top priority, working closely with you to drive impactful, sustainable growth.
          </p>
          
          <Link to='/about'><button href='/' className='learn-more-button'>Learn More <BsArrowRight className='arrow-icon'/></button></Link>

          <div className='media-icons'>
            <a href='https://www.instagram.com/koraldigital/' target='_blank' rel='noreferrer'><AiOutlineInstagram /></a>
            <a href='https://yelp.com/biz/koral-digital-allen' target='_blank' rel='noreferrer'><FaYelp /></a>
            <a href='https://www.google.com/search?q=koral+digital&sxsrf=ALiCzsYXtji7tCWnY8K8Gd3pLeyndl697Q%3A1659465298021&source=hp&ei=UW7pYqbROoyp5OUP-LCCwA4&iflsig=AJiK0e8AAAAAYul8YprwqCIdzlUE8vPS4BCrnPTCyElW&ved=0ahUKEwjmvej85aj5AhWMFLkGHXiYAOgQ4dUDCAk&uact=5&oq=koral+digital&gs_lp=Egdnd3Mtd2l6uAED-AEBMgQQIxgnwgIHECMY6gIYJ8ICERAuGIAEGLEDGIMBGMcBGNEDwgINEC4YsQMYxwEY0QMYCsICBRAAGIAEwgIIEAAYgAQYsQPCAggQLhixAxiDAcICFBAuGIAEGLEDGIMBGMcBGNEDGNQCwgIEEAAYQ8ICBxAuGLEDGEPCAggQLhiABBixA8ICChAAGLEDGIMBGEPCAgsQABiABBixAxiDAcICCxAuGIAEGLEDGMkDwgIFEAAYkgPCAgsQLhiABBjHARivAcICChAuGLEDGIMBGArCAggQABixAxiDAcICBBAAGArCAgcQABixAxgKwgIKEAAYsQMYgwEYCsICBRAuGIAEwgIKEAAYHhgPGBYYCsICBRAAGIYDqAIKSNgbUNgMWMAZcAF4AMgBAJABAJgB2gGgAc4SqgEGMC4xMi4x&sclient=gws-wiz' target='_blank' rel='noreferrer'><SiGooglemybusiness /></a>
            <a href='mailto: support@koraldigital.com' target='_blank' rel='noreferrer'><AiOutlineMail /></a>
          </div>
      </div>
    </div>
    </motion.div>
  )
}

export default Home